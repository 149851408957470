import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/font.ts\",\"import\":\"Lato\",\"arguments\":[{\"variable\":\"--font-sans\",\"style\":[\"normal\",\"italic\"],\"weight\":[\"100\",\"300\",\"400\",\"700\",\"900\"],\"display\":\"swap\",\"preload\":true,\"subsets\":[\"latin\"]}],\"variableName\":\"Sans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/font.ts\",\"import\":\"Libre_Baskerville\",\"arguments\":[{\"variable\":\"--font-serif\",\"style\":[\"normal\",\"italic\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"preload\":true,\"subsets\":[\"latin\"]}],\"variableName\":\"Serif\"}");
;
import(/* webpackMode: "eager" */ "/app/src/styles/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/context/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/external/LazyGoogleTagManagerHead.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/external/LazyGoogleTagManagerIframe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/external/LazyImbox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/external/LazyKlarnaSDK.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/external/LazyVoyadoScript.tsx");
